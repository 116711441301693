<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Permit</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="3"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="permitRequest.status"
                        label="Active"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="3"
                    md="12"
                    sm="12"
                >
                    <label>Upload Document?</label>
                    <v-radio-group 
                        v-model="permitRequest.uploadDocument" 
                        row
                    >
                        <v-radio
                            label="Yes"
                            :value="1"
                            color="var(--color__main)"
                        ></v-radio>
                        <v-radio
                            label="No"
                            :value="0"
                            color="var(--color__main)"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="12"
                    sm="12"
                >
                    <label>Show Contractor?</label>
                    <v-radio-group 
                        v-model="permitRequest.showContractor" 
                        row
                    >
                        <v-radio
                            label="Yes"
                            :value="1"
                            color="var(--color__main)"
                        ></v-radio>
                        <v-radio
                            label="No"
                            :value="0"
                            color="var(--color__main)"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <label>Title</label>
                    <v-text-field
                        v-model="permitRequest.title" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <label>Description</label>
                    <v-textarea
                        v-model="permitRequest.description"
                        single-line
                        rows="3"
                        row-height="30"
                        outlined
                    >
                    </v-textarea>
                </v-col>
            </v-row>

            <ActionButtons
                :request="permitRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />

            <ActionDialog 
                v-on:methodConfirmToCall="dialog.methodConfirm"
                :showDialog.sync="dialog.show"
                :headerTitle="dialog.headerText"
                :bodyText="dialog.bodyText"
                :params="dialog.params"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { required, validEmail } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";
    import ActionDialog from "@/components/Layout/ActionDialog";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons,
            ActionDialog
        },

        data: () => ({

            validForm: true,

            permitRequest: {
                id: 0,
                title: "",
                description: "",
                uploadDocument: 1,
                showContractor: 1,
            },
            
            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            loading: false,
            
            validations: {
                required: required,
                email: validEmail,
            }
        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("permitModule/GetById", this.id);

                    if (response.success) {
                        this.permitRequest = response.result;
                    }
                }
                else {
                    this.permitRequest.status = 1;
                }
            },

            async cancel() {
                this.$router.push({ path: "/common/permitList" });
            },            

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {
                    this.loading = true;
                    this.showLoading();

                    if (this.permitRequest.status === null) {
                        this.permitRequest.status = 0;
                    }

                    if (this.permitRequest.uploadDocument === null) {
                        this.permitRequest.uploadDocument = 0;
                    }

                    if (this.permitRequest.showContractor === null) {
                        this.permitRequest.showContractor = 0;
                    }

                    this.permitRequest.id = this.id;

                    const result = await this.$store.dispatch("permitModule/CreateUpdate", this.permitRequest);

                    if (result.success === true) {

                        this.showToast("success", "Success!", result.message);
                        this.cancel();
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            }
        },

        async created() {
            this.showLoading();

            await this.getRegister();

            this.hideLoading();
        }
    })
</script>